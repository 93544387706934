import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

// 解决重复点击相同路由报错问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
  {
    path: '',
    redirect: '/pc_index'
  },
  /** pc端路由 */
  // 首页
  {
    path: '/pc_index',
    name: 'PcIndex',
    component: () => import('../views/pc/index.vue')
  },
  // 产品功能页
  {
    path: '/pc_product',
    name: 'PcProduct',
    component: () => import('../views/pc/product.vue')
  },
  // 智能硬件页
  {
    path: '/pc_intelligent',
    name: 'PcIntelligent',
    component: () => import('../views/pc/intelligent.vue')
  },
  // 服务版本页
  {
    path: '/pc_server',
    name: 'PcServer',
    component: () => import('../views/pc/server.vue')
  },
  // 关于我们页
  {
    path: '/pc_about',
    name: 'PcAbout',
    component: () => import('../views/pc/about.vue')
  },
  /** 移动端路由 */
  // 首页
  {
    path: '/mb_index',
    name: 'MbIndex',
    component: () => import('../views/mb/index.vue')
  },
  // 产品功能
  {
    path: '/mb_product',
    name: 'MbProduct',
    component: () => import('../views/mb/product.vue')
  },
  // 智能硬件
  {
    path: '/mb_intelligent',
    name: 'MbIntelligent',
    component: () => import('../views/mb/intelligent.vue')
  },
  // 服务版本
  {
    path: '/mb_server',
    name: 'MbServer',
    component: () => import('../views/mb/server.vue')
  },
  // 关于我们
  {
    path: '/mb_about',
    name: 'MbAbout',
    component: () => import('../views/mb/about.vue')
  }
]

const router = new VueRouter({
  base: '/',
  mode: 'history',
  routes
})

// 切换页面时回到顶部
router.beforeEach((to, from, next) => {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: "smooth"
  })
  next()
})

export default router
