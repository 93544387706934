<template>
  <LayOut></LayOut>
</template>

<script>
// // 友盟统计
// var _czc = _czc || [];
// (function () {
//   var um = document.createElement("script");
//   um.src = "https://v1.cnzz.com/z.js?id=1281329278&async=1";
//   var s = document.getElementsByTagName("script")[0];
//   s.parentNode.insertBefore(um, s);
// })();

window.onload = function () {
  document.addEventListener('touchstart', function (event) {
    if (event.touches.length > 1) {
      event.preventDefault()
    }
  })
  document.addEventListener('gesturestart', function (event) {
    event.preventDefault()
  })
}

import LayOut from './components/LayOut.vue'

export default {
  name: "App",
  components: {
    LayOut
  },
}
</script>


