<template>
  <div class="header">
    <img class="logo" v-lazy="IMG_URL + 'logo.png'" alt="">
    <img @click="maskFlag = true" class="choose" v-lazy="MB_IMG_URL + 'choose.png'" alt="">
    <div class="mask" v-show="maskFlag">
      <ul class="animate__animated animate__fadeInRight">
        <li @click="handleClick(item.id)" v-for="item in navBarList" :key="item.id">
          <img v-lazy="MB_IMG_URL + item.icon" alt="">
          {{ item.label }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      navBarList: [{ id: 0, label: '首页', icon: 'icon1.png' }, { id: 1, label: '产品功能', icon: 'icon2.png' }, { id: 2, label: '智能硬件', icon: 'icon3.png' }, { id: 3, label: '版本服务', icon: 'icon4.png' }, { id: 4, label: '关于我们', icon: 'icon5.png' }],
      maskFlag: false
    }
  },
  methods: {
    handleClick(id) {
      this.maskFlag = false
      switch (id) {
        case 0:
          return this.$router.push('/mb_index');
        case 1:
          return this.$router.push('/mb_product');
        case 2:
          return this.$router.push('/mb_intelligent');
        case 3:
          return this.$router.push('/mb_server');
        case 4:
          return this.$router.push('/mb_about')
        default:
          return;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 20.17rem;
  background: #FFF;
  padding: 0 6.83rem;
  box-sizing: border-box;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 111;

  .logo {
    width: 26.67rem;
    height: 8.5rem;
  }

  .choose {
    width: 9.17rem;
    height: 8.17rem;
  }

  .mask {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: #00000069;

    ul {
      background: #FFF;
      position: absolute;
      top: 20.17rem;
      right: 3.5rem;
      width: 56.33rem;
      // height: 89.33rem;
      border-radius: 2rem;
      background: #FFF;
      box-shadow: 0 0 2rem 0.5rem #ffffff40;
      color: #282828;
      font-size: 5.33rem;
      font-weight: 400;
      padding: 0 4.17rem;
      box-sizing: border-box;

      li {
        text-align: left;
        padding: 5.33rem 5.67rem;
        box-sizing: border-box;
        border-bottom: solid 0.17rem #DDDDDD;

        img {
          width: 7rem;
          height: 7rem;
        }
      }

      li:last-child {
        border-bottom: none;
      }
    }

    ul::before {
      content: "";
      width: 0;
      height: 0;
      border-left: 2.5rem solid transparent;
      border-right: 2.5rem solid transparent;
      border-bottom: 2.5rem solid #fff;
      position: absolute;
      top: -2.5rem;
      right: 5rem;
    }
  }
}
</style>