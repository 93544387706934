<template>
  <div class="header" id="header">
    <img class="logo" v-lazy="IMG_URL + 'logo.png'" alt="">
    <ul>
      <li @click="handleClick(item.id)" :class="navbarIdx == item.id ? 'active' : ''" v-for="item in navbar"
        :key="item.id">{{ item.label }}</li>
    </ul>
    <div class="btn">
      <button class="login" @click="handleLogin">登录</button>
      <button class="try" @click="handleTry">免费试用</button>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      navbar: [
        { id: 0, label: '首页' },
        { id: 1, label: '产品功能' },
        { id: 2, label: '智能硬件' },
        { id: 3, label: '服务版本' },
        { id: 4, label: '关于我们' },
      ],
      navbarIdx: 0,
      scrollTop: 0
    }
  },
  watch: {
    // 页面滚动时导航栏背景色透明度变为1
    scrollTop(newValue) {
      const element = document.getElementById('header')
      if (newValue <= 100) {
        element.style.backgroundColor = 'rgba(255, 255, 255, 0.61)'
        element.style.boxShadow = 'none'
      } else {
        element.style.backgroundColor = 'rgba(255, 255, 255, 1)'
        element.style.boxShadow = '0 0 0.5rem 0.06rem #deeeff'
      }
    }
  },
  mounted() {
    this.handleScroll();
  },
  methods: {
    // 浏览器滚动
    handleScroll() {
      window.addEventListener('scroll', () => {
        this.scrollTop = window.scrollY;
      })
    },
    // 导航栏切换
    handleClick(id) {
      this.navbarIdx = id
      switch (id) {
        case 0:
          return this.$router.push('/');
        case 1:
          return this.$router.push('/pc_product');
        case 2:
          return this.$router.push('/pc_intelligent');
        case 3:
          return this.$router.push('/pc_server');
        case 4:
          return this.$router.push('/pc_about');
        default:
          return;
      }
    },
    // 登录
    handleLogin() {
      _czc.push(["_trackEvent", "官网顶部", "登录"])
      window.open('https://dip.igongwu.com/login')
    },
    // 注册试用
    handleTry() {
      _czc.push(["_trackEvent", "官网顶部", "注册"])
      window.open('https://dip.igongwu.com/login?type=register')
    }
  }
}
</script>

<style lang="scss" scoped>
.header {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 5.25rem;
  align-items: center;
  background-color: rgba(255, 255, 255, .61);
  z-index: 2;
  transition: all .2s ease;

  .logo {
    width: 7.16rem;
    height: 2.36rem;
    margin-left: 2.56rem;
  }

  ul {
    display: flex;
    margin-left: 2.13rem;

    li {
      color: #2e2e2e;
      font-size: 1.13rem;
      font-weight: 400;
      line-height: 1.79rem;
      margin-left: 5.31rem;
      cursor: pointer;
      transition: all 0.2s ease;
      white-space: nowrap;

      &::after {
        content: '';
        width: 0;
        display: block;
        height: 0.19rem;
        margin: 0.13rem auto 0;
        background-color: #2264EE;
        transition: all 0.2s ease;
      }
    }

    .active {
      display: flex;
      flex-direction: column;
      color: #2264EE;
      font-weight: 500;

      &::after {
        width: 50%;
      }
    }
  }

  .btn {
    margin-left: 30.38rem;

    .login,
    .try {
      min-width: 7rem;
      height: 2.88rem;
      flex-shrink: 0;
      border-radius: 0.38rem;
      border: solid 0.06rem #286BFF;
      color: #286bff;
      text-align: center;
      font-size: 1.13rem;
      font-weight: 400;
      white-space: nowrap;
      cursor: pointer;
    }


    .login {
      margin-right: 1.31rem;
    }

    // .try {
    //   background-color: #EFF4FF;
    // }

    .login:hover,
    .try:hover {
      background-color: #EFF4FF;
    }
  }
}
</style>