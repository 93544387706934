<template>
  <div class="slider" id="floatDiv">
    <img @click="handleClick" v-lazy="MB_IMG_URL + imgUrl" alt="">
  </div>
</template>

<script>
export default {
  data() {
    return {
      imgUrl: 'customer-active.png',
      // 滚动条参数
      oldScrollTop: 0,
      scrollTop: 0,
      // 控制是否展示回到顶部
      toTop: false
    }
  },
  watch: {
    scrollTop(newValue, oldValue) {
      const element = document.getElementById('floatDiv')
      setTimeout(() => {
        if (newValue == window.scrollY) {
          this.oldScrollTop = newValue;
          element.style.right = '4rem'
          this.imgUrl = 'customer-active.png'
        }
      }, 200);
      if (this.oldScrollTop == oldValue) {
        element.style.right = "-6rem"
        this.imgUrl = 'customer.png'
      }
    }
  },
  mounted() {
    this.handleScroll();
  },
  methods: {
    // 浏览器滚动
    handleScroll() {
      window.addEventListener('scroll', () => {
        this.scrollTop = window.scrollY;
      })
    },
    // 53客服
    handleClick() {
      var _53code = document.createElement("script");
      _53code.src = "https://tb.53kf.com/code/code/e7947fd5e4009e40547964bebdb69f7b5/1";
      _53code.setAttribute("async", true); // 可选，异步加载脚本
      _53code.addEventListener("load", function () { });
      var s = document.getElementsByTagName("script")[0];
      s.parentNode.insertBefore(_53code, s);
    }
  }
}
</script>

<style lang="scss" scoped>
.slider {
  top: 50%;
  right: 4rem;
  position: fixed;
  -webkit-transition: 0.3s ease;
  transition: 0.3s ease;
  z-index: 11;

  img {
    width: 15.33rem;
    height: 16.17rem;
  }
}
</style>